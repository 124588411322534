import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { randomId } from "./../../util/util";

import Uppy from "@uppy/core";
// import Tus from '@uppy/tus'
import XHRUpload from "@uppy/xhr-upload";
import { Dashboard } from "@uppy/react";

// And their styles (for UI plugins)
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "./uppy.css";

const UppyDashboard = forwardRef((props, ref) => {

  const { uploadEndpoint, onAllUploadSuccess, onUploadComplete } = props;

  // console.log('init UppyDashboard');

  const uppyId = randomId();

  useImperativeHandle(ref, () => ({
    addMore() {
      const uppyEle = document.getElementById(uppyId);
      if (uppyEle) {
        const addMoreEles = uppyEle.getElementsByClassName("uppy-DashboardContent-addMore");
        if (addMoreEles && addMoreEles[0]) {
          const addMoreBtn = addMoreEles[0];
          if (addMoreBtn) {
            addMoreBtn.click();
          }
        }
      }
    }
  }))


  const uppy = Uppy({
    restrictions: { maxNumberOfFiles: 10, allowedFileTypes: ["image/*"] },
    autoProceed: true
    // allowMultipleUploads: false
  });

  uppy.use(XHRUpload, {
    endpoint: uploadEndpoint,
    method: 'post',
    formData: true,
    getResponseError(responseText) {
      let err = 'Unknown Error';
      let errorJSON;
      try {
        errorJSON = JSON.parse(responseText);
        const errors = errorJSON.errors;
        if (errors && errors instanceof Array) {
          err = errors[0].message;
        }
      } catch (e) { }
      return new Error(err);
    }
  });

  useEffect(() => {
    // uppy.setMeta({ project: project.name });

    //componentDidMount
    uppy.on('upload-success', (file) => {
      // uppy.removeFile(file.id);
      const filesDisplaying = uppy.getFiles();
      if (onAllUploadSuccess && filesDisplaying.length <= 0) {
        onAllUploadSuccess();
      }
    })

    uppy.on('complete', () => {
      if (onUploadComplete) {
        onUploadComplete();
      }
    });

    // uppy.on('thumbnail:generated', (file, preview) => {
    // })

    // componentWillUnmount
    return () => {
      uppy.close();
    };

  }, [uppy, onAllUploadSuccess, onUploadComplete]);

  const handleUppyReset = e => {
    e.preventDefault();
    uppy.reset();
  }

  return (
    <div id={uppyId} className="aib-uppy">
      <button className="btn btn-light" style={{ position: 'absolute', left: '45%', bottom: '5px', zIndex: 1006 }} onClick={handleUppyReset}>Reset</button>
      <Dashboard
        uppy={uppy}
        width={770}
        proudlyDisplayPoweredByUppy={false}
      />
    </div>
  );
});

export default React.memo(UppyDashboard);
