import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Swal from "sweetalert2";
import { FaListUl } from "react-icons/fa";
import { RiLayoutBottom2Line } from "react-icons/ri";
import { BsSquare } from 'react-icons/bs';

import Workbox, { WorkboxContainer, WorkboxHeader, calcWorkboxHeights, WB_SIZE_MEDIUM, WB_SIZE_MAX } from '../workbox/Workbox';
import { toggleExpanded, updateProjectName, setChildrenExpanded, setProjectWorkBoxHeaderColour } from './../../store/actions/projectActions';
import { WorkboxBody } from './../workbox/Workbox';

// import styled from 'styled-components';
import Loading from '../loading/Loading';
import ProjectWorkboxSlider from '../projects/ProjectWorkboxSlider';


export const PROJECT_WB_MAX_WIDTH = '70rem';

// const Container = styled.div`
//   margin-top: 0.25rem;
//   margin-bottom: 0.25rem;
// `;


const _ProjectWorkbox = (props) => {
  const {
    title,
    project,
    isExpanded,
    hasChildren,
    isLoading,
    updateProjectName,
    height,
    scrollIntoView,
    onExpand,
    onCollapse,
    onSliderShow,
    headerColour
  } = props;

  // const { PROJECT_NESTED } = ItemType;
  const { id, name, imageUrl, parentProjectId } = project;

  const [wbHeight, setWBHeight] = useState(height);
  const [showTimeline, setShowTimeline] = useState(false);
  const [showList, setShowList] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [scrollRef, scrollIntoView])

  const handleTitleEditClick = async () => {
    const result = await Swal.fire({
      title: "update project name",
      input: "text",
      inputValue: name,
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Update",
      showLoaderOnConfirm: true,
      preConfirm: async (titleValue) => {
        const proj = { id, name: titleValue };
        try {
          await updateProjectName(proj);
          return proj.name;
        }
        catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

    if (result.value) {
      Swal.fire({
        icon: 'success',
        title: "Updated",
        text: result.value,
        timer: 3000
      })
    }
  }

  const handleLeftHeaderSpaceClick = () => {
    setShowTimeline(!showTimeline);
  }

  const handleRightHeaderSpaceClick = () => {
    const newWbHeight = calcWorkboxHeights(wbHeight, WB_SIZE_MAX);
    setWBHeight(newWbHeight);
  }

  const handleTitleClick = () => {
    const newWbHeight = calcWorkboxHeights(wbHeight, WB_SIZE_MEDIUM);
    setWBHeight(newWbHeight);
  }

  const handleSliderToggle = () => {
    const showSlider = !showTimeline;
    if (showSlider && onSliderShow) {
      onSliderShow(project.id);
    }
    setShowTimeline(showSlider);
  }

  const handleListToggle = () => {
    if (isExpanded) {
      if (onCollapse) {
        onCollapse(id);
      }
    } else {
      if (onExpand) {
        onExpand(id);
      }
    }
    setShowList(!showList);
  }

  let iconsRight, iconsLeft;
  if (hasChildren) {

    // console.log(`_ProjectWorkbox id=${id} -- isLoading=${isLoading} -- hasChildren=${hasChildren}`);
    iconsRight = [
      isLoading ? <Loading /> : <FaListUl size={22} title="list projects" onClick={handleListToggle} />,
      <BsSquare size={22} title="select" style={{ transform: 'initial' }} />
    ]


    iconsLeft = [
      isLoading ? <Loading /> : <RiLayoutBottom2Line size={28} title="show/hide slider" onClick={handleSliderToggle} />
    ]
  }

  // console.log(`ProjectWorkbox id=${id} height=${height} wbHeight=${wbHeight}`);

  return (
    <div>
      <WorkboxContainer innerRef={scrollIntoView ? scrollRef : null} style={{ maxWidth: PROJECT_WB_MAX_WIDTH }}>
        {(hasChildren && showTimeline) &&
          <div style={{ maxWidth: "66rem", textAlign: "center", margin: "0 2rem" }}>
            <ProjectWorkboxSlider parentProjectId={id} />
          </div>
        }
        <Workbox>
          <WorkboxHeader
            title={title ?? name}
            height={wbHeight}
            headerColour={headerColour}
            onTitleEditClick={handleTitleEditClick}
            onTitleClick={handleTitleClick}
            onLeftHeaderSpaceClick={handleLeftHeaderSpaceClick}
            onRightHeaderSpaceClick={handleRightHeaderSpaceClick}
            headerIconsRight={iconsRight}
            headerIconsLeft={iconsLeft}
          />
          <WorkboxBody height={wbHeight}>
            <div className="row">
              <div className="col-1 text-left">
                <button className="btn btn-sm btn-block btn-secondary">New</button>
              </div>
              {(wbHeight && wbHeight === WB_SIZE_MEDIUM)
                ?
                <div className="col-10">
                  <img src={imageUrl} style={{ maxWidth: '150px' }} alt={name} />
                </div>
                :
                <div className="col-10 text-center">
                  <img src={imageUrl} className="img-fluid" alt={name} />
                </div>
              }
              <div className="col-1 text-right">
                <button className="btn btn-sm btn-block btn-secondary">Views</button>
              </div>
            </div>
          </WorkboxBody>
        </Workbox>
      </WorkboxContainer>
    </div>

  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProjectName: (project) => dispatch(updateProjectName(project)),
    setChildrenExpanded: (parentProjectId, isExpanded) => dispatch(setChildrenExpanded(parentProjectId, isExpanded)),
    setProjectWorkBoxHeaderColour: (parentProjectId, colour) => dispatch(setProjectWorkBoxHeaderColour(parentProjectId, colour)),
    toggleExpanded: (projectId) => dispatch(toggleExpanded(projectId))
  }
}

const ProjectWorkbox = connect(null, mapDispatchToProps)(_ProjectWorkbox);

export default ProjectWorkbox;
