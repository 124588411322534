import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Project from '../project/Project.js';
import Slider from '../slider/Slider'
import Loading from './../loading/Loading';
import { fetchProjects } from '../../store/actions/projectActions'
import { selectRootProjects } from "./../../store/selectors/project";
import { MAIN_SLIDER_SIZE } from './../../pages/Profile';

function ProjectsSlider({ fetchProjects, showingSlider, projects, isLoading }) {

  useEffect(() => {
    fetchProjects(true);
  }, [fetchProjects]);

  if (isLoading && !projects.length) {
    return (
      <div className="mw-100">
        <div className="container-fluid px-1 text-center">
          <Loading color='success' />
        </div>
      </div>
    )
  }

  return (
    <>
      {(!isLoading && projects && projects.length > 0 && showingSlider) &&
        <Slider>
          {projects && projects.map(p => <Project key={p.id + p.isActive} project={p} />)}
        </Slider>
      }
    </>
  );

}

const mapStateToProps = (state) => {
  const { isLoading } = state.projects;
  const { mainSliderSize, showMainSlider } = state.profile;
  return {
    isLoading,
    projects: selectRootProjects(state),
    showingSlider: (showMainSlider && mainSliderSize === MAIN_SLIDER_SIZE.MAX)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjects: (showLoading) => dispatch(fetchProjects(showLoading))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSlider);