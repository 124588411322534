import React from 'react';
import SlickSlider from 'react-slick';
import { findAncestor } from './../../util/DOMUtil';
import { randomId } from './../../util/util';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import './slider.css';

export const SLIDER_MEDIUM = "MEDIUM";

export function SliderCard({ children, id, active, size, onCardSelect }) {

  const handleCardOnClick = (e) => {
    e.preventDefault();
    if (onCardSelect) {
      onCardSelect(id);
    }
  }

  let classess = "slider-card";
  if (active === true) {
    classess += " slider-card-active";
  }
  if (size === SLIDER_MEDIUM) {
    classess += " slider-card-md";
  }

  return (
    <div className={classess} onClick={handleCardOnClick}>
      {children}
    </div>
  );
}

export function SliderImage({ url, alt }) {
  return (
    <div className="slider-card-image">
      <img src={url} alt={alt} />
    </div>
  );
}

function SliderNextArrow({ className, style, onClick, size }) {
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      {(size && size === SLIDER_MEDIUM)
        ? (<IoIosArrowForward color="#000" />)
        : (<IoIosArrowForward color="#fff" />)
      }
      {/* <img src={RightArrow} alt="prev" /> */}
    </div>
  );
}

function SliderPrevArrow({ className, style, onClick, size }) {
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      {(size && size === SLIDER_MEDIUM)
        ? (<IoIosArrowBack color="#000" />)
        : (<IoIosArrowBack color="#fff" />)
      }
      {/* <img src={LeftArrow} alt="next" /> */}
    </div>
  );
}

const defaultSettings = {
  speed: 600,
  infinite: false,
  draggable: false,
  accessibility: false,
  variableWidth: true,
  slidesToShow: 17,
  slidesToScroll: 17,
  adaptiveHeight: true,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,
  className: "timeline-slider",
  responsive: [
    {
      breakpoint: 4620,
      settings: {
        slidesToShow: 16,
        slidesToScroll: 16
      }
    },
    {
      breakpoint: 4700,
      settings: {
        slidesToShow: 15,
        slidesToScroll: 15
      }
    },
    {
      breakpoint: 4329,
      settings: {
        slidesToShow: 14,
        slidesToScroll: 14
      }
    },
    {
      breakpoint: 4040,
      settings: {
        slidesToShow: 13,
        slidesToScroll: 13
      }
    },
    {
      breakpoint: 3754,
      settings: {
        slidesToShow: 12,
        slidesToScroll: 12
      }
    },
    {
      breakpoint: 3468,
      settings: {
        slidesToShow: 11,
        slidesToScroll: 11
      }
    },
    {
      breakpoint: 3179,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 10
      }
    },
    {
      breakpoint: 2894,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 9
      }
    },
    {
      breakpoint: 2609,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8
      }
    },
    {
      breakpoint: 2322,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7
      }
    },
    {
      breakpoint: 2034,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6
      }
    },
    {
      breakpoint: 1749,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5
      }
    },
    {
      breakpoint: 1465,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 1178,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 890,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const mediumSettings = {
  speed: 600,
  infinite: false,
  draggable: false,
  accessibility: false,
  variableWidth: true,
  slidesToShow: 5,
  slidesToScroll: 5,
  adaptiveHeight: true,
  nextArrow: <SliderNextArrow size={SLIDER_MEDIUM} />,
  prevArrow: <SliderPrevArrow size={SLIDER_MEDIUM} />,
  className: "timeline-slider timeline-slider-md",
  responsive: [
    {
      breakpoint: 2322,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 2034,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 1749,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 1465,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 1178,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 890,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const LEFT = 'LEFT';
const RIGHT = 'RIGHT';

class Slider extends React.Component {

  constructor(props) {
    super(props);
    this.sliderId = randomId();
  }

  state = {
    isKeyDown: false
  }

  // not a react way // may need to move to parent compoenent when more than one slider in a page
  move = (direction) => {
    const slider = document.getElementById(this.sliderId);
    if (slider) {
      const slides = slider.getElementsByClassName('slider-card');
      if (slides) {
        for (let index = 0; index < slides.length; index++) {
          const slide = slides.item(index);
          if (slide && slide.classList && slide.classList.contains('slider-card-active')) {
            const position = direction === LEFT ? index - 1 : index + 1
            const next = slides.item(position);

            if (next) {
              next.click();
              const ancestorSlickSlide = findAncestor(next, '.slick-slide');
              if (ancestorSlickSlide.getAttribute('aria-hidden') === 'true') {
                if (direction === LEFT) {
                  this.slider.slickPrev();
                } else {
                  this.slider.slickNext();
                }
              }
              break;
            }
          }
        }
      }
    }
  }

  stopMoving = () => {
    this.setState({ isKeyDown: false })
  }

  startMoving = (direction) => {
    if (!this.state.isKeyDown) {
      this.move(direction);
    }
  }

  handleKeyPress = (e) => {
    e.preventDefault();
    const keyCode = parseInt(e.keyCode);
    switch (keyCode) {
      case 37: // right arrow
        this.startMoving(LEFT);
        this.setState({ isKeyDown: true });
        break;
      case 39: // left arrow
        this.startMoving(RIGHT);
        this.setState({ isKeyDown: true });
        break;
      case 35: // End key
        const slider = document.getElementById(this.sliderId);
        if (slider) {
          const slides = slider.getElementsByClassName('slick-slide');
          if (slides) {
            const to = slides.length;
            this.slider.slickGoTo(to);
          }
        }
        break;
      case 36: // Home key
        this.slider.slickGoTo(0);
        break;
      default:
    }
  }


  render() {
    const { children, size } = this.props;

    const childrens = React.Children.map(children, child => {
      return React.cloneElement(child, { size })
    })

    let sliderSettings = defaultSettings;
    if (size && size === SLIDER_MEDIUM) {
      sliderSettings = mediumSettings;
    }

    return (
      <div tabIndex={tabIndex++} id={this.sliderId} onKeyDown={this.handleKeyPress} onKeyUp={this.stopMoving}>
        <SlickSlider ref={s => (this.slider = s)} {...sliderSettings}>
          {childrens}
        </SlickSlider>
      </div>
    );
  }
}

let tabIndex = 0;
export default Slider;