import React from 'react';
import './curtain.css';

const Curtain = ({ children }) => {
  return (
    <div className="curtain d-flex justify-content-center align-items-center">
      {children}
    </div>
  );
}

export default Curtain;