import { createSelector } from "reselect";

const getRootProjectId = (state) => state.projects.rootId;
const getAllProjects = (state) => state.projects.items;
const projectItemById = (state, projectId) => {
  return state.projects.items[projectId]
};
const showProjectsList = (state) => state.profileWorkbox.showProjectsList;

export const selectRootProjects = createSelector(
  [getRootProjectId, getAllProjects],
  (rootId, items) => {
    if (items && items[rootId]) {
      const projectIds = items[rootId]?.children ?? [];
      return projectIds.map(projectId => items[projectId]?.data);
    }
    return {};
  }
);

export const selectProjectsByParentId = createSelector(
  [projectItemById, getAllProjects],
  (project, items) => {
    const projectId = project?.id;
    if (projectId !== undefined && items && items[projectId]) {
      const projectIds = items[projectId]?.children ?? [];
      return projectIds.map(projectId => items[projectId]?.data);

    }
    return [];
  }
);

/**
 * Find and returns active project id by parentId.
 * Return null if no active project found for the parentId.
 * 
 * @param {Number} parentId 
 * @param {Object} items 
 */
const findActiveProjectId = (parentId, items) => {
  if (Number.isInteger(parentId) && items[parentId]) {
    const children = items[parentId]?.children;
    if (Array.isArray(children)) {
      for (const id of children) {
        if (items[id]?.data?.isActive === true) {
          return id; // should be only one active per tree level
        }
      }
    }
  }
  return null;
}

export const selectProjectsTree = createSelector(
  [getRootProjectId, getAllProjects, showProjectsList],
  (rootProjectId, items, showProjectsList) => {

    let tree = { ...items };

    if (!showProjectsList) {
      const newTree = {};

      const childrenItems = {};
      let parentId = rootProjectId;
      while (Number.isInteger(parentId)) {
        let activeProjectId = findActiveProjectId(parentId, items);

        if (Number.isInteger(activeProjectId)) {

          newTree[parentId] = {
            ...items[parentId],
            children: [activeProjectId]
          }

          // if the item is active
          // then display the active project only with it's chiildrens
          // if selected project is expanded then also show/include it's children in projects tree

          const activeProjectChildren = [];
          const activeProject = items[activeProjectId];
          if (activeProject?.isExpanded) {
            const children = activeProject?.children;
            for (const c of children) {
              activeProjectChildren.push(c);
              childrenItems[c] = {
                ...items[c]
              }
            }
          } else {
            // console.log("expanded => " + false);
            // const activeChildId = findActiveProjectId(activeProjectId, items);
            // if (Number.isInteger(activeChildId)) {
            //   activeProject.isExpanded = true;
            // const children = activeProject?.children;
            // for (const c of children) {
            //   activeProjectChildren.push(c);
            //   childrenItems[c] = {
            //     ...items[c]
            //   }
            // }
            // }
          }


          newTree[activeProjectId] = {
            ...items[activeProjectId],
            children: activeProjectChildren
          }

        }

        parentId = activeProjectId;
      }

      if (Number.isInteger(newTree[rootProjectId]?.id)) {
        tree = { ...items, ...newTree, ...childrenItems };
      } else {
        tree = {}
      }

    }


    // console.log("selectProjectsTree");
    // console.log("newItems => " + JSON.stringify(tree, null, 2));

    return {
      rootId: rootProjectId,
      items: tree
    };
  }
);

export const selectAllProjectsTree = createSelector(
  [getRootProjectId, getAllProjects],
  (rootProjectId, items) => {
    return {
      rootId: rootProjectId,
      items: items
    };
  }
);

export const selectProjectsTreeReversed = createSelector(
  [getRootProjectId, getAllProjects],
  (rootProjectId, items) => {
    let ids = items[rootProjectId]?.children;
    if (ids && ids.length > 0) {
      ids = ids.reverse();
      items[rootProjectId].children = ids;
    }
    return {
      rootId: rootProjectId,
      items: items
    };
  }
);

export const activeRootItem = createSelector(
  [getRootProjectId, getAllProjects],
  (rootId, items) => {
    const children = items[rootId]?.children;
    if (Array.isArray(children) && children.length > 0) {
      for (const id of children) {
        if (items[id]?.data?.isActive) {
          return items[id];
        }
      }
    }
    return {};
  }
);
