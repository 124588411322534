import React from 'react';
import { connect } from 'react-redux';
import { setIsActive, deleteProject, setDeleteError } from '../../store/actions/projectActions';
import { setProfileWorkboxHeight, setShowButtons } from '../../store/actions/profileWorkboxActions';
import { setAutoMinimizeProfileWorkbox } from '../../store/actions/appActions';
import { SliderCard, SliderImage } from '../slider/Slider';
import Loading from './../loading/Loading';
import Curtain from './../curtain/Curtain';
import { WB_SIZE_MIN } from '../workbox/Workbox';
import { MdClose } from 'react-icons/md';
import './project.css';
import Alert from './../alert/Alert';


const Project = (props) => {

  const {
    project,
    setIsActive,
    setProfileWorkboxHeight,
    deleteProject,
    isDeletingProject,
    deleteError,
    setDeleteError,
    setShowProfileWorkboxButtons,
    autoMinimizeProfileWorkbox,
    setAutoMinimizeProfileWorkbox
  } = props;

  const { id, name, imageUrl, isActive } = project;
  console.log(`Project { id=${id} isActive=${isActive} }`)

  const handleProjectOnClick = () => {
    if (!isDeletingProject) {
      setIsActive(id, !isActive);
      // console.log('profileWorkboxHeight => ' + profileWorkboxHeight);
      if (autoMinimizeProfileWorkbox) {
        setProfileWorkboxHeight(WB_SIZE_MIN);
        setShowProfileWorkboxButtons(false);
        setAutoMinimizeProfileWorkbox(false);
      }
    }
  };

  const handleOnClickRemove = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDeletingProject) {
      deleteProject(id);
    }
  };

  let showCurtain = false;
  let deleteErrorMessage = '';
  if (isActive) {
    if (isDeletingProject) {
      showCurtain = true;
    }

    if (deleteError && deleteError.id && deleteError.id === id && deleteError.message) {
      deleteErrorMessage = true;
    }
  }

  const handleOnAlertClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteError({});
  }

  return (
    <SliderCard id={id} active={isActive} onCardSelect={handleProjectOnClick}>
      <SliderImage url={imageUrl} alt={name} />
      {isActive === true &&
        <div className="ab-slide-remove" onClick={handleOnClickRemove} title="delete project" >
          <MdClose size={22} />
        </div>
      }

      {deleteErrorMessage &&
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} onClick={handleOnAlertClose}>
          <Alert type='danger' text={deleteError.message} />
        </div>
      }
      {showCurtain &&
        <Curtain>
          <Loading color='white' />
          <span className="text-white ml-2 font-weight-bolder" style={{ fontSize: '1.2em' }}>Deleting...</span>
        </Curtain>
      }
    </SliderCard>
  );
}

const mapStateToProps = (state) => {
  const { isDeletingProject, deleteError } = state.projects;
  const { autoMinimizeProfileWorkbox } = state.app;
  return {
    isDeletingProject,
    deleteError,
    autoMinimizeProfileWorkbox
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsActive: (projectId, isActive) => dispatch(setIsActive(projectId, isActive)),
    setProfileWorkboxHeight: (height) => dispatch(setProfileWorkboxHeight(height)),
    setShowProfileWorkboxButtons: (showButtons) => dispatch(setShowButtons(showButtons)),
    deleteProject: (projectId) => dispatch(deleteProject(projectId)),
    setDeleteError: (deleteError) => dispatch(setDeleteError(deleteError)),
    setAutoMinimizeProfileWorkbox: (autoMinimize) => dispatch(setAutoMinimizeProfileWorkbox(autoMinimize))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);