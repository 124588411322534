import React, { useState, Fragment } from "react";
import Slider, { SLIDER_MEDIUM } from "../slider/Slider";
import { BsPencilSquare } from "react-icons/bs";
import { FaCog } from "react-icons/fa";
import styled from 'styled-components';
import "./workbox.css";


const WBContainer = styled.div`
  margin-left: auto!important;
  margin-right: auto!important;
  max-width: 75rem;
  border: none;
`;

export const WB_SIZE_MIN = "wb_card_sm";
export const WB_SIZE_MEDIUM = "wb_card_md";
// export const WB_SIZE_LARGE = "wb_card_lg";
export const WB_SIZE_MAX = "";

export const WB_CONTROLLS_ACTIVE_COLOR = "#FF7E00"
const WB_HEADER_ICONS_COLOUR = "#D3D3E1";

export function WorkboxContainer({ children, style, innerRef }) {
  return (
    <WBContainer
      ref={innerRef ? innerRef : null}
      style={style}
    >
      {children}
    </WBContainer>
  );
}

export function WorkboxSlider({ children }) {
  return (
    <Slider size={SLIDER_MEDIUM}>
      {children}
    </Slider>
  );
}

export function calcWorkboxHeights(currentWbHeight, maxWbHeight) {
  let newHeight = "";
  switch (currentWbHeight) {
    case WB_SIZE_MIN: {
      newHeight = maxWbHeight;
      break;
    }
    case WB_SIZE_MEDIUM: {
      if (maxWbHeight === WB_SIZE_MEDIUM) {
        newHeight = WB_SIZE_MIN;
      }
      if (maxWbHeight === WB_SIZE_MAX) {
        newHeight = WB_SIZE_MAX;
      }
      break;
    }
    case WB_SIZE_MAX: {
      if (maxWbHeight === WB_SIZE_MAX) {
        newHeight = WB_SIZE_MIN;
      }
      if (maxWbHeight === WB_SIZE_MEDIUM) {
        newHeight = WB_SIZE_MEDIUM;
      }
      break;
    }
    default: { }
  }
  return newHeight;
}

export function WorkboxHeader(props) {
  const {
    innerRef,
    // height,
    headerColour,
    title,
    onTitleEditClick,
    onTitleClick,
    onLeftHeaderSpaceClick,
    onRightHeaderSpaceClick,
    headerIconsRight,
    headerIconsLeft
  } = props;
  const [cogActive, setCogActive] = useState(false);
  const [showTitleEditIcon, setShowTitleEditIcon] = useState(false);

  const handleLeftHeaderSpaceClick = () => {
    if (onLeftHeaderSpaceClick) {
      onLeftHeaderSpaceClick();
    }
  }

  const handleRightHeaderSpaceClick = (e) => {
    e.preventDefault();
    if (onRightHeaderSpaceClick) {
      onRightHeaderSpaceClick();
    }
  }

  const handleEditTitleClick = (event) => {
    event.preventDefault();
    if (onTitleEditClick) {
      onTitleEditClick();
    }
  }

  const handleShowControlls = (event) => {
    event.preventDefault();
    setCogActive(!cogActive);
    if (onTitleEditClick) {
      setShowTitleEditIcon(!showTitleEditIcon);
    }
  }

  const handleTitleClick = (e) => {
    e.preventDefault();
    if (onTitleClick) {
      onTitleClick();
    }
  }

  return (
    <div ref={innerRef} className="card-header wb_card_header" style={{ background: headerColour, borderRadius: "0.5rem" }}>
      <div className="wb_card_header_left">
        <div className="wb_card_header_icon">
          <FaCog size={22} onClick={handleShowControlls} color={cogActive ? WB_CONTROLLS_ACTIVE_COLOR : WB_HEADER_ICONS_COLOUR} />
          {headerIconsLeft && headerIconsLeft.map((icon, i) => <Fragment key={i}>{icon}</Fragment>)}
        </div>
        <div className="wb_card_header_space" onClick={handleLeftHeaderSpaceClick} title="hide/show main timeline"></div>
      </div>
      <div className="wb_card_header_center">
        <div className={`wb_card_header_title c_pointer`} onClick={handleTitleClick} title="click to change size">
          <p className="badge badge-pill badge-dark" style={{ backgroundColor: "#808080" }}>
            <span className="text-truncate px-1 title_text">{title}</span>
          </p>
        </div>
      </div>
      <div className="wb_card_header_right">
        <div className="wb_card_header_icon">
          {showTitleEditIcon && <BsPencilSquare size={22} onClick={handleEditTitleClick} color={WB_CONTROLLS_ACTIVE_COLOR} title="edit" />}
        </div>
        <div className="wb_card_header_space" onClick={handleRightHeaderSpaceClick} title="resize"></div>
        <div className="wb_card_header_icon">
          {headerIconsRight && headerIconsRight.map((icon, i) => <Fragment key={i}>{icon}</Fragment>)}
        </div>
      </div>
    </div>
  );
}

export function WorkboxBody({ children, height }) {
  return (
    <div className={`card-body wb_card_body ${height}`}>
      {children}
    </div>
  );
}

const Workbox = ({ children }) => {
  return (
    <div className="card wb_card" style={{ border: 0 }}>
      {children}
    </div>
  );
}

export default Workbox;