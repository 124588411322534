import React from 'react';

const Loading = ({ color, small }) => {
  let classes = 'spinner-border';
  if (color) {
    classes += ` text-${color}`;
  }

  if (small) {
    classes += ' spinner-border-sm';
  }

  return (
    <div className={classes} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default Loading;