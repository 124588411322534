import { SET_SHOW_CHAT, AUTO_MINIMIZE_PROFILE_WORKBOX } from './../actions/appActions';

const initState = {
  showChat: false,
  autoMinimizeProfileWorkbox: true
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SHOW_CHAT:
      return { ...state, showChat: action.show }
    case AUTO_MINIMIZE_PROFILE_WORKBOX:
      return { ...state, autoMinimizeProfileWorkbox: action.autoMinimize }
    default:
      return state;
  }
};

export default appReducer;