import React from 'react';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/image_tui.min.js';

import FroalaEditorComponent from 'react-froala-wysiwyg';

const editorKey = process.env.REACT_APP_TEXT_EDITOR_LIC_KEY;

class TextEditor extends React.Component {

  // handleContentChange = (content) => {
  //   // console.log("content => " + content);
  //   const { onContentChange } = this.props;
  //   if (onContentChange) {
  //     onContentChange(content);
  //   }
  // }


  render() {
    const { placeholder, content, autoSaveURL } = this.props;

    // console.log("TextEditor content: " + content);

    const config = {
      toolbarInline: true,
      charCounterCount: false,
      placeholderText: placeholder,
      heightMax: 470,
      // initOnClick: true, // does not work when toolbarInline: true
      attribution: false,
      key: editorKey,
      lineHeights: {
        Default: '1.3',
        Single: '1',
        '1.15': '1.15',
        '1.5': '1.5',
        Double: '2'
      },

      events: {
        // "initialized": function (e, editor) {
        // this.toolbar.show();
        // this.toolbar.showInline(null, false);
        // },
        "image.beforeUpload": function (files) {
          var editor = this;
          if (files.length) {
            // Create a File Reader.
            var reader = new FileReader();
            // Set the reader to insert images when they are loaded.
            reader.onload = function (e) {
              var result = e.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };
            // Read image as base64.
            reader.readAsDataURL(files[0]);
          }
          editor.popups.hideAll();
          // Stop default upload chain.
          return false;
        }
      }
    }

    if (autoSaveURL) {
      config.saveInterval = 2500;
      config.saveParam = "content";
      config.saveURL = "/api/v1/user/save-description.php";
      config.saveMethod = "POST";
    }

    return (
      <FroalaEditorComponent
        tag="textarea"
        model={content}
        // onModelChange={this.handleContentChange}
        config={config}
      />
    );
  }

}

export default TextEditor;