import projectReducer from './projectReducer';
import profileWorkboxReducer from './profileWorkboxReducer';
import appReducer from './appReducer';
import profileReducer from './profile/index';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  app: appReducer,
  profile: profileReducer,
  projects: projectReducer,
  profileWorkbox: profileWorkboxReducer
});

export default rootReducer;