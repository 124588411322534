import React from 'react';
import ProfileWorkbox from '../components/profile/ProfileWorkbox';
import ProjectsSlider from '../components/projects/ProjectsSlider';
// import ProjectsList from '../components/projects/ProjectsList';
import ProjectsTree from '../components/projects/ProjectsTree';

export const MAIN_SLIDER_SIZE = {
  MED: "MED",
  MAX: "MAX"
}

function Profile() {
  return (
    <div className="mw-100">
      <div className="container-fluid px-1">
        <ProjectsSlider />
        <ProfileWorkbox />
        {/* <ProjectsList /> */}
        <ProjectsTree />
      </div>
    </div>
  );
}


export default Profile;
