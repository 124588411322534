import React from 'react';
import { connect } from 'react-redux';
import { selectProjectsByParentId } from "../../store/selectors/project";
import { SliderCard, SliderImage } from '../slider/Slider';
import { WorkboxSlider } from '../workbox/Workbox';
import { setIsActive, setChildrenExpanded } from './../../store/actions/projectActions';

function ProjectWorkboxSlider({ parentProjectId = 0, projects, activeProjectId, setIsActive }) {

  const handleOnCardSelect = (newActiveId) => {
    const isActive = activeProjectId !== newActiveId;

    // hack code not good. maybe should find diff solution
    // const project = projects.find(p => p.id === parentProjectId);
    // const prevIsExpanded = project?.prevIsExpanded;
    // if (prevIsExpanded !== undefined && prevIsExpanded !== null) {
    //   setChildrenExpanded(parentProjectId, prevIsExpanded)
    // }

    setIsActive(newActiveId, isActive);
  }

  return (
    <WorkboxSlider>
      {projects.map((p, i) => (
        <SliderCard key={i + p.active} id={p.id} active={p.isActive} onCardSelect={handleOnCardSelect}>
          <SliderImage url={p.imageUrl} alt={p.name} />
        </SliderCard>
      ))}
    </WorkboxSlider>
  );
}

const mapStateToProps = (state, props) => {
  const { parentProjectId } = props;

  const projects = selectProjectsByParentId(state, parentProjectId);
  let activeProjectId = undefined;
  for (const idx in projects) {
    const p = projects[idx];
    if (p && p.isActive) {
      activeProjectId = p.id;
      break;
    }
  }

  return {
    projects,
    activeProjectId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsActive: (projectId, isActive) => dispatch(setIsActive(projectId, isActive)),
    setChildrenExpanded: (projectId, isExpanded) => dispatch(setChildrenExpanded(projectId, isExpanded))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectWorkboxSlider);