import React from "react";
import Tree, { moveItemOnTree, mutateTree } from "@atlaskit/tree";
import { connect } from 'react-redux';
import { WB_SIZE_MIN } from '../workbox/Workbox';
import ProjectWorkbox, { PROJECT_WB_MAX_WIDTH } from './../project-workbox/ProjectWorkbox';
import { updateDisplayPosition, addParentProject, addProjects, fetchProjects, fetchProjectsByParentId, toggleExpanded, setChildrenExpanded, setProjectWorkBoxHeaderColour, setHasChildren, addUiDataToProjects, setChildrenIsLoading } from '../../store/actions/projectActions';
import { selectProjectsTree, selectAllProjectsTree, activeRootItem } from "./../../store/selectors/project";
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 0.25rem;
`;

const ProjectsTree = (props) => {
  const {
    showProjectsList,
    activeRootItem,
    tree,
    allProjectsTree,
    updateDisplayPosition,
    addParentProject,
    addProjects,
    fetchProjectsByParentId,
    setChildrenIsLoading,
    setChildrenExpanded,
    setProjectWorkBoxHeaderColour,
    setHasChildren
  } = props;

  // const reverseRootChildren = (tree) => {
  //   let treeData = { ...tree };
  //   const rootId = treeData?.rootId;
  //   let ids = treeData?.items[0]?.children;
  //   if (rootId !== undefined && Array.isArray(ids) && ids.length > 0) {
  //     ids = ids.reverse();
  //     treeData.items[rootId].children = ids;
  //   }
  //   return treeData;
  // }

  // const findProjectById = (tree, projectId) => {
  //   if (projectId) {
  //     const items = tree?.items;
  //     for (const itemId of Object.keys(items)) {
  //       const items = items[itemId];
  //       const project = items?.data;
  //       if (project?.id === projectId) {
  //         return project;
  //       }
  //     }
  //   }
  //   return {};
  // }

  const findProjectByItemIndex = (tree, parentId, itemIndex) => {
    const items = tree?.items ?? {};
    const childrenIds = items[parentId]?.children ?? [];
    if (Array.isArray(childrenIds) && childrenIds.length > 0) {
      return items[childrenIds[itemIndex]]?.data ?? {};
    }
    return {};
  }

  const handleOnCollapse = (projectId) => {
    // const newTree = mutateTree(tree, projectId, { isExpanded: false });
    const newTree = mutateTree(allProjectsTree, projectId, { isExpanded: false });
    if (newTree.items) {
      setChildrenExpanded(projectId, false);
    }
  }

  const handleOnExpand = (projectId) => {
    // const newTree = mutateTree(tree, projectId, { isExpanded: true });
    const newTree = mutateTree({ ...allProjectsTree }, projectId, { isExpanded: true });
    if (newTree.items) {
      addProjects({ ...newTree.items });
      // addProjects({ ...allProjectsTree.items, ...newTree.items });
      fetchProjectsById(projectId, newTree.items);
    }
  }

  const handleSliderShow = (projectId) => {
    fetchProjectsById(projectId, allProjectsTree.items, false);
  }

  const fetchProjectsById = (projectId, currentProjects, isExpanded = true) => {
    fetchProjectsByParentId({
      projectId,
      onStart: () => {
        setChildrenIsLoading(projectId, true);
      },
      onSuccess: (json) => {
        let projectItems = json?.results?.items ?? {};
        const rootId = json?.results?.rootId;

        const newItems = addUiDataToProjects(projectItems);
        const children = newItems[rootId]?.children ?? [];
        if (newItems[rootId]) {
          delete newItems[rootId];
        }

        const items = { ...currentProjects, ...newItems };
        items[rootId].children = children;
        items[rootId].isChildrenLoading = false;

        const isRootExpanded = items[rootId].isExpanded;
        items[rootId].isExpanded = isRootExpanded ?? isExpanded;

        addProjects(items);
      },
      onError: () => {
        setChildrenIsLoading(projectId, false);
      }
    });
  }

  const isInt = (variable) => {
    return Number.isInteger(variable);
  }

  // const handleDragStart = (itemId) => {
  //   setChildrenExpanded(itemId, false);
  // }

  const handleDragEnd = (source, destination) => {
    // console.log("\n ========");
    // console.log("handleDragEnd source");
    // console.log(JSON.stringify(source, null, 2));
    // console.log("handleDragEnd destination");
    // console.log(JSON.stringify(destination, null, 2));

    // console.log("handleDragEnd tree");
    // console.log(JSON.stringify(tree, null, 2));

    if (!destination) {
      return;
    }

    const srcParentId = source?.parentId; // ?? parseInt(source.parentId);
    const destParentId = destination?.parentId; // ?? parseInt(destination.parentId);
    const srcItemIndex = source?.index; // ?? parseInt(source.index);
    const destItemIndex = destination?.index; // ?? parseInt(destination.index);
    // console.log(`srcParentId=${srcParentId} -- srcItemIndex=${srcItemIndex} -- destParentId=${destParentId} -- destItemIndex=${destItemIndex}`);
    if (srcParentId === undefined) {
      return;
    }

    // if (!isInt(srcParentId) || !isInt(destParentId) || !isInt(srcItemIndex) || !isInt(destItemIndex)) {
    //   return;
    // }

    const newTree = moveItemOnTree(tree, source, destination);

    const srcProject = findProjectByItemIndex(tree, srcParentId, srcItemIndex);
    // console.log("handleDragEnd srcProject");
    // console.log(JSON.stringify(srcProject, null, 2));

    const destProject = findProjectByItemIndex(tree, destParentId, destItemIndex);
    // console.log("handleDragEnd destProject");
    // console.log(JSON.stringify(destProject, null, 2));

    // const tree = newTree; //reverseRootChildren(newTree);

    // console.log("handleDragEnd tree");
    // console.log(JSON.stringify(tree, null, 2));


    // item moved on top of different parent (combine mode)
    // if (destItemIndex === undefined && srcParentId !== destParentId) {
    if (srcParentId !== destParentId) {
      // console.log("combine");
      addParentProject({
        projectId: srcProject.id,
        projectDisplayPositionIndex: destItemIndex === undefined ? null : destItemIndex,
        parentProjectId: destParentId ?? null,
        onStart: () => {
          // console.log("Tree addParentProject() onStart()");
          addProjects(newTree.items);

          // fix destination project (set headerColour and hasChidlren props)
          // const parentHeaderColour = newTree?.items[destParentId]?.headerColour;
          // const headerColour = !parentHeaderColour && destParentId > 0 ? randomHexLightColorCode() : parentHeaderColour;
          // console.log(`parentHeaderColour=${parentHeaderColour} -- headerColour=${headerColour}`);
          // if (!parentHeaderColour) {
          //   setProjectWorkBoxHeaderColour(destParentId, headerColour);
          //   setHasChildren(destParentId, true);
          // }

          const srcParentHasChildren = newTree?.items[srcParentId]?.hasChildren;
          // setProjectWorkBoxHeaderColour(srcProject.id, headerColour);



          // fix source parent project (remove headerColour and hasChildren if does not have childrens anymore)
          // if srcParent do not hasChildren then setHasChildren to false for srcParent
          //         get parentOfSrcParent colour and apply the same colour to srcParent

          // if (!hasChildren && destParentId === 0) {
          if (!srcParentHasChildren) {
            setHasChildren(srcParentId, false);

            // const srcParentsParentId = newTree?.items[srcParentId]?.data.parentProjectId;
            // const srcParentColour = !srcParentsParentId ? "" : newTree?.items[srcParentId]?.headerColour;
            // setProjectWorkBoxHeaderColour(srcParentId, srcParentColour);
          }

        },
        onSuccess: (json) => {
          const projectId = json?.results?.id;
          const headerColour = json?.results?.headerColour;
          if (projectId && headerColour) {
            setProjectWorkBoxHeaderColour(projectId, headerColour);
          }
          // fetchProjects(false); // need to manually update the projectId colour rather than fetching all again.
        },
        onError: () => {
          addProjects(tree.items);
          // console.log("Tree addParentProject() onFail()");
        }
      });
    }

    // item moved under same parent
    if (isInt(destItemIndex) && isInt(srcItemIndex) && srcParentId === destParentId) {
      // console.log("item moved under same parent");
      // updateDisplayPosition(tree.items, srcProject, destProject);
      addProjects(newTree.items);
      updateDisplayPosition({
        srcProject,
        destProject,
        onError: () => { addProjects(tree.items); }
      });
    }

    // item moved under different parent
    // if (destItemIndex !== undefined && srcParentId !== destParentId) {
    //   console.log("item moved under different parent")
    // }

  }

  const renderItem = ({ item, onExpand, onCollapse, provided, snapshot }) => {
    const project = item.data;

    // let isSelected = (selectedProject && selectedProject.hasOwnProperty('id')) ? (project.id === selectedProject.id) : false;
    // console.log(`render item(${project.id})  item.isChildrenLoading => ${item.isChildrenLoading} -- item.isExpanded => ${item.isExpanded}`)
    return (
      <Container
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        isDragging={snapshot.isDragging}
      >
        <ProjectWorkbox
          key={project.id + project.name + project.isActive}
          project={project}
          height={project.isActive ? '' : WB_SIZE_MIN}
          headerColour={project.headerColour}
          isExpanded={item.isExpanded}
          hasChildren={item.hasChildren}
          isLoading={item.isChildrenLoading}
          scrollIntoView={project.isActive ?? false}
          onExpand={handleOnExpand}
          onCollapse={handleOnCollapse}
          onSliderShow={handleSliderShow}
        />
      </Container>
    )
  }


  const showTree = showProjectsList || (activeRootItem && activeRootItem.hasOwnProperty('id'));

  // console.log("ProjectsTree showTree: " + showTree);
  // console.log("ProjectsTree tree");
  // console.log(JSON.stringify(tree, null, 2));

  return (
    <>
      {/* {(!showProjectsList && selectedProject && selectedProject.hasOwnProperty('id')) && <ProjectWorkbox key={selectedProject.id + selectedProject.name} project={selectedProject} />} */}
      {showTree &&
        <div className="mx-auto" style={{ maxWidth: PROJECT_WB_MAX_WIDTH, height: "100%" }}>
          <Tree
            tree={tree}
            renderItem={renderItem}
            onExpand={handleOnExpand}
            onCollapse={handleOnCollapse}
            onDragEnd={handleDragEnd}
            // onDragStart={handleDragStart}
            offsetPerLevel={0}
            isDragEnabled
            isNestingEnabled
          />
        </div>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const { showProjectsList } = state.profileWorkbox;
  return {
    tree: selectProjectsTree(state),
    allProjectsTree: selectAllProjectsTree(state),
    activeRootItem: activeRootItem(state),
    showProjectsList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addProjects: (tree) => dispatch(addProjects(tree)),
    updateDisplayPosition: (argsObj) => dispatch(updateDisplayPosition(argsObj)),
    fetchProjects: (showLoading) => dispatch(fetchProjects(showLoading)),
    fetchProjectsByParentId: (parentProjectId, isLoading) => dispatch(fetchProjectsByParentId(parentProjectId, isLoading)),
    setChildrenExpanded: (projectId, isExpanded) => dispatch(setChildrenExpanded(projectId, isExpanded)),
    toggleExpanded: (projectId) => dispatch(toggleExpanded(projectId)),
    addParentProject: (argsObj) => dispatch(addParentProject(argsObj)),
    setProjectWorkBoxHeaderColour: (projectId, headerColour) => dispatch(setProjectWorkBoxHeaderColour(projectId, headerColour)),
    setHasChildren: (projectId, hasChildren) => dispatch(setHasChildren(projectId, hasChildren)),
    setChildrenIsLoading: (projectId, isLoading) => dispatch(setChildrenIsLoading(projectId, isLoading))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTree);