import React, { useEffect } from 'react';
import './chat.css';

const Chatbox = () => {

  useEffect(() => {
    initChat();
  }, []);

  const initChat = () => {
    const $ = window.$;
    hideChat(0);

    $('#prime').click(function () {
      toggleFab();
    });


    //Toggle chat and links
    function toggleFab() {
      $('.prime').toggleClass('zmdi-comment-outline');
      $('.prime').toggleClass('zmdi-close');
      $('.prime').toggleClass('is-active');
      $('.prime').toggleClass('is-visible');
      $('#prime').toggleClass('is-float');
      $('.chat').toggleClass('is-visible');
      $('.fab').toggleClass('is-visible');

    }

    $('#chat_first_screen').click(function (e) {
      hideChat(1);
    });



    $('#chat_fourth_screen').click(function (e) {
      hideChat(4);
    });

    $('#chat_fullscreen_loader').click(function (e) {
      $('.fullscreen').toggleClass('zmdi-window-maximize');
      $('.fullscreen').toggleClass('zmdi-window-restore');
      $('.chat').toggleClass('chat_fullscreen');
      $('.fab').toggleClass('is-hide');
      $('.header_img').toggleClass('change_img');
      $('.img_container').toggleClass('change_img');
      $('.chat_header').toggleClass('chat_header2');
      $('.fab_field').toggleClass('fab_field2');
      $('.chat_converse').toggleClass('chat_converse2');
      //$('#chat_converse').css('display', 'none');
      // $('#chat_body').css('display', 'none');
      // $('#chat_form').css('display', 'none');
      // $('.chat_login').css('display', 'none');
      // $('#chat_fullscreen').css('display', 'block');
    });

    function hideChat(hide) {
      switch (hide) {
        case 0:
          $('#chat_converse').css('display', 'none');
          $('#chat_body').css('display', 'none');
          $('#chat_form').css('display', 'none');
          $('.chat_login').css('display', 'block');
          $('.chat_fullscreen_loader').css('display', 'none');
          $('#chat_fullscreen').css('display', 'none');
          break;
        case 1:
          $('#chat_converse').css('display', 'block');
          $('#chat_body').css('display', 'none');
          $('#chat_form').css('display', 'none');
          $('.chat_login').css('display', 'none');
          $('.chat_fullscreen_loader').css('display', 'block');
          break;
        case 2:
          $('#chat_converse').css('display', 'none');
          $('#chat_body').css('display', 'block');
          $('#chat_form').css('display', 'none');
          $('.chat_login').css('display', 'none');
          $('.chat_fullscreen_loader').css('display', 'block');
          break;
        case 3:
          $('#chat_converse').css('display', 'none');
          $('#chat_body').css('display', 'none');
          $('#chat_form').css('display', 'block');
          $('.chat_login').css('display', 'none');
          $('.chat_fullscreen_loader').css('display', 'block');
          break;
        case 4:
          $('#chat_converse').css('display', 'none');
          $('#chat_body').css('display', 'none');
          $('#chat_form').css('display', 'none');
          $('.chat_login').css('display', 'none');
          $('.chat_fullscreen_loader').css('display', 'block');
          $('#chat_fullscreen').css('display', 'block');
          break;
        default:
          break;
      }
    }
  }




  return (
    <div className="chatbox">
      <div className="fabs">
        <div className="chat">
          <div className="chat_header">
            <div className="chat_option">
              <div className="header_img">
                <img src="http://res.cloudinary.com/dqvwa7vpe/image/upload/v1496415051/avatar_ma6vug.jpg" alt="user" />
              </div>
              <span id="chat_head">Some Body</span>
              <br />
              <a href="/" onClick={e => e.preventDefault()} className="agent">Arty Person <i className="zmdi zmdi-arrow-right" ></i> </a>
              <span id="chat_fullscreen_loader" className="chat_fullscreen_loader"><em className="fullscreen zmdi zmdi-window-maximize"></em></span>
            </div>

          </div>
          <div className="chat_body chat_login">
            <a href="/" onClick={e => e.preventDefault()} id="chat_first_screen" className="fab"><i className="zmdi zmdi-arrow-right"></i></a>
            <p>hey whats doin?</p>
          </div>
          <div id="chat_converse" className="chat_conversion chat_converse">
            <a href="/" onClick={e => e.preventDefault()} id="chat_second_screen" className="fab"><i className="zmdi zmdi-arrow-right"></i></a>
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>Hey there!</span>
            <span className="chat_msg_item chat_msg_item_user">
              Im doing arty stuff</span>
            <span className="status">20m ago</span>
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>Oh really, cool, same here</span>
            <span className="chat_msg_item chat_msg_item_user">
              Me to, designing a chat section actualy</span>
            <span className="status2">Awesome!!</span>
          </div>

          <div id="chat_form" className="chat_converse chat_form">
            <a href="/" onClick={e => e.preventDefault()} id="chat_fourth_screen" className="fab"><i className="zmdi zmdi-arrow-right"></i></a>
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>Hey how do I do a smiley face?</span>
            <span className="chat_msg_item chat_msg_item_user">
              Hello!</span>
            <span className="status">20m ago</span>
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>I dont know, I havent designed that bit yet.
            <div>
                <br />
                <form className="get-notified">
                  <label htmlFor="chat_log_email">Oh, well (smiley face)</label>
                  <input id="chat_log_email" placeholder="Enter your email" />
                  <i className="zmdi zmdi-chevron-right"></i>
                </form>
              </div></span>

            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>Im going to go do some more arty stuff
            <div>
                <form className="message_form">
                  <input placeholder="Your email" />
                  <input placeholder="Technical issue" />
                  <textarea rows="4" placeholder="Your message"></textarea>
                  <button>Send</button>
                </form>

              </div></span>
          </div>
          <div id="chat_fullscreen" className="chat_conversion chat_converse">
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">
                {/* <img src="http://res.cloudinary.com/dqvwa7vpe/image/upload/v1496415051/avatar_ma6vug.jpg" /> */}
              </div>Any questions before u go?</span>
            <span className="chat_msg_item chat_msg_item_user">
              Not really</span>
            <div className="status">20m ago</div>
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>Hey! have you seen lorem ipsum before?</span>
            <span className="chat_msg_item chat_msg_item_user">
              Not sure what to say really....</span>
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
            <span className="chat_msg_item chat_msg_item_user">
              Where can I get some?</span>
            <span className="chat_msg_item chat_msg_item_admin">
              <div className="chat_avatar">

              </div>The standard chuck...</span>
            <span className="chat_msg_item chat_msg_item_user">
              There are many variations of passages of Lorem Ipsum available</span>
            <div className="status2">cool, will grab some later.</div>
            <span className="chat_msg_item ">
              <ul className="tags">
                <li>Hats</li>
                <li>T-Shirts</li>
                <li>Pants</li>
              </ul>
            </span>
          </div>
          <div className="fab_field">
            <a href="/" onClick={e => e.preventDefault()} id="fab_camera" className="fab"><i className="zmdi zmdi-camera"></i></a>
            <a href="/" onClick={e => e.preventDefault()} id="fab_send" className="fab"><i className="zmdi zmdi-mail-send"></i></a>
            <textarea id="chatSend" name="chat_message" placeholder="Send a message" className="chat_field chat_message"></textarea>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Chatbox;