import React from 'react';

const Alert = ({ type, text }) => {

  // const handleOnCloseClick = e => {
  //   e.prventDefault();
  //   e.stopPropagation();
  // }

  const alertType = type ? type : 'primary';

  return (
    <div className={`alert alert-${alertType} alert-dismissible fade show`} role="alert">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <strong>{text}</strong>
    </div>
  );
}

export default Alert;