import { Action } from "./../../actions/profile"
import { MAIN_SLIDER_SIZE } from './../../../pages/Profile';

const initState = {
  showMainSlider: true,
  mainSliderSize: MAIN_SLIDER_SIZE.MED
};

const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case Action.SET_MAIN_SLIDER_SIZE: {
      return { ...state, mainSliderSize: action.mainSliderSize }
    }
    case Action.SET_SHOW_MAIN_SLIDER: {
      return { ...state, showMainSlider: action.showMainSlider }
    }
    default:
      return state;
  }
};

export default profileReducer;