import {
  SET_PROFILE_WORKBOX_HEIGTH,
  SET_SHOW_UPPY,
  SET_SHOW_MENU,
  SET_SHOW_BUTTONS,
  SHOW_PROJECTS_LIST
} from './../actions/profileWorkboxActions';

const initState = {
  height: '',
  showMenu: false,
  showUppy: false,
  showButtons: true,
  showProjectsList: false
};

const profileWorkboxReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PROFILE_WORKBOX_HEIGTH:
      return { ...state, height: action.height }
    case SET_SHOW_MENU:
      return { ...state, showMenu: action.showMenu }
    case SET_SHOW_UPPY:
      return { ...state, showUppy: action.showUppy }
    case SET_SHOW_BUTTONS:
      return { ...state, showButtons: action.showButtons }
    case SHOW_PROJECTS_LIST:
      return { ...state, showProjectsList: action.showProjectsList }
    default:
      return state;
  }
};

export default profileWorkboxReducer;
