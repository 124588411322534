import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from "../../util/httpClient";
import Workbox, { WorkboxContainer, WorkboxHeader, WorkboxBody, calcWorkboxHeights, WB_SIZE_MEDIUM, WB_SIZE_MAX } from '../workbox/Workbox';
import { setShowMenu, setShowUppy, setShowButtons, setShowProjectsList, setProfileWorkboxHeight } from '../../store/actions/profileWorkboxActions'
import UppyDashboard from './../uppy/UppyDashboard';
import { fetchProjects, setShowProjects } from './../../store/actions/projectActions';
import TextEditor from './../text-editor/TextEditor';
import ProjectWorkboxSlider from '../projects/ProjectWorkboxSlider';
import { FaListUl } from "react-icons/fa";
import { RiLayoutBottom2Line } from "react-icons/ri";
import { BsSquare } from 'react-icons/bs';

import styles from './profile-workbox.module.css';
import styled from 'styled-components';
import { MAIN_SLIDER_SIZE } from '../../pages/Profile';
import { setMainSliderSize, setShowMainSlider } from './../../store/actions/profile/index';

const Container = styled.div`
  margin-bottom: 0.25rem;
`;

class ProfileWorkbox extends PureComponent {

  constructor(props) {
    super(props);
    this.uppyRef = React.createRef();
  }

  state = {
    profileDescription: {
      content: "",
      isLoading: true,
    }
  }

  componentDidMount() {
    this.fetchProfileDescription();
  }

  fetchProfileDescription = async () => {
    try {
      const json = await get("/api/v1/user/get-description.php");
      const desc = json.data ? json.data : {};
      this.setState({ profileDescription: { content: desc.content, isLoading: false } })
    } catch (e) {
      console.log('failed to get description content');
      this.setState({ profileDescription: { isLoading: false } })
    }
  }

  handleShowMenu = () => {
    this.props.setShowMenu(true);
    this.props.setShowUppy(false);
  };

  handleShowUppyDashboard = () => {
    this.props.setShowMenu(false);
    this.props.setShowUppy(true);
  };

  handleOnCloseButtonClick = () => {
    this.props.setShowMenu(false);
    this.props.setShowUppy(false);
  }

  handleAllUploadSuccess = () => {
    const { getProjectsWithoutShowLoading, setShowMenu, setShowUppy } = this.props;
    getProjectsWithoutShowLoading();
    setShowMenu(false);
    setShowUppy(false);
  };

  handleOnUploadComplete = () => {
    this.props.getProjectsWithoutShowLoading();
  };

  handleLeftHeaderSpaceClick = () => {
    const { showMainSlider, setShowMainSlider } = this.props;
    setShowMainSlider(!showMainSlider);
  }

  handleRightHeaderSpaceClick = () => {
    const newWbHeight = calcWorkboxHeights(this.props.wbHeight, WB_SIZE_MAX);
    this.props.setProfileWorkboxHeight(newWbHeight);
  }

  handleTitleClick = () => {
    const newWbHeight = calcWorkboxHeights(this.props.wbHeight, WB_SIZE_MEDIUM);
    this.props.setProfileWorkboxHeight(newWbHeight);
  }

  handleDisplaySlider = () => {
    const { mainSliderSize, setMainSliderSize } = this.props;
    const newSize = mainSliderSize !== MAIN_SLIDER_SIZE.MED ? MAIN_SLIDER_SIZE.MED : MAIN_SLIDER_SIZE.MAX
    setMainSliderSize(newSize);
  }

  // onContentChange = (content) => {
  //   this.props.setProfileDescription(content);
  // }

  // saveContent = () => {
  //   const { saveProfileDescription, profileDescription } = this.props;
  //   saveProfileDescription(profileDescription);
  // }

  handleShowProjectsList = (e) => {
    e.preventDefault();
    const { showProjectsList, setShowProjectsList } = this.props;
    setShowProjectsList(!showProjectsList);
  }

  handleOnSliderCardSelect = (activeProjectId) => {
    // set project item isActive to true
    // set all other projects for same parent isActive to false
  }

  render() {

    const {
      wbHeight,
      showMenu,
      showUppy,
      showMainSlider,
      mainSliderSize,
      rootId
    } = this.props;

    const Menu = () => {

      return (
        <div className="row">
          <div className="col-1 text-center p-0">
            <button className={`btn btn-sm btn-secondary ${styles.wb_body_btn}`} onClick={this.handleOnCloseButtonClick}>Close</button>
          </div>
          <div className="col-10">
            <nav className={`${styles.wb_menu} ${wbHeight === WB_SIZE_MEDIUM ? styles.wb_menu_md : ""}`} >
              <ul className="list-group list-group-flush shadow">
                <li className="list-group-item list-group-item-action" onClick={this.handleShowUppyDashboard}>Projects</li>
                <li className="list-group-item list-group-item-action">Groups</li>
                <li className="list-group-item list-group-item-action">Doc</li>
                <li className="list-group-item list-group-item-action">Image</li>
                <li className="list-group-item list-group-item-action">Blank Project</li>
                <li className="list-group-item list-group-item-action">Create Image</li>
              </ul>
            </nav>
          </div>
          <div className="col-1 text-center p-0">
          </div>
        </div>
      );
    };

    const iconsRight = [
      <FaListUl size={22} title="list projects" onClick={this.handleShowProjectsList} />,
      <BsSquare size={22} title="select" style={{ transform: 'initial' }} />
    ]

    const iconsLeft = [
      <RiLayoutBottom2Line size={28} title="resize slider" onClick={this.handleDisplaySlider} />
    ]

    let displayUppy = 'd-none', displayMenu = 'd-none', displayMember = 'd-none';
    if (!showUppy) {
      if (showMenu) {
        displayMenu = '';
      } else {
        displayMember = ''
      }
    } else {
      displayUppy = '';
    }


    const { content, isLoading } = this.state.profileDescription;

    // console.log("ProfileWorkbox profileDescription.content: " + content);
    // console.log("ProfileWorkbox profileDescription.isLoading: " + isLoading);

    return (
      <Container>
        <WorkboxContainer style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          {(showMainSlider && mainSliderSize === MAIN_SLIDER_SIZE.MED) && <ProjectWorkboxSlider parentProjectId={rootId} />}
          <Workbox>
            <WorkboxHeader
              title="Bradley McGrath"
              height={wbHeight}
              headerColour="#363B59"
              headerIconsRight={iconsRight}
              headerIconsLeft={iconsLeft}
              onTitleClick={this.handleTitleClick}
              onLeftHeaderSpaceClick={this.handleLeftHeaderSpaceClick}
              onRightHeaderSpaceClick={this.handleRightHeaderSpaceClick}
            />
            <WorkboxBody height={wbHeight}>
              <div style={{ maxWidth: "70rem", margin: "0 auto" }}>
                <div className="row">
                  <div className="col-1 text-left">
                    <button className="btn btn-sm btn-block btn-secondary" onClick={this.handleShowMenu}>New</button>
                  </div>
                  <div className="col-10 w-100">
                    <div className={styles.main_profile}>
                      <div className={displayUppy}>
                        <div className="row justify-content-center">
                          <div className="col-1 text-center p-0">
                            <button className={`btn btn-sm btn-secondary ${styles.wb_body_btn}`} onClick={this.handleOnCloseButtonClick}>Close</button>
                          </div>
                          <div className="col-10">
                            <UppyDashboard
                              ref={this.uppyRef}
                              uploadEndpoint="/api/v1/project/create-with-image.php"
                              onUploadComplete={this.handleOnUploadComplete}
                              onAllUploadSuccess={this.handleAllUploadSuccess}
                            />
                          </div>
                          <div className="col-1 text-center p-0">
                            <button className={`btn btn-sm btn-secondary ${styles.wb_body_btn}`} onClick={() => { this.uppyRef.current.addMore() }}>Add</button>
                          </div>
                        </div>
                      </div>
                      <div className={displayMenu}>
                        <Menu />
                      </div>
                      <div className={displayMember}>
                        {!isLoading && <TextEditor id="profile-editor" content={content} placeholder="Add profile description here." autoSaveURL="/api/v1/user/save-description.php" />}
                      </div>
                    </div>
                  </div>
                  <div className="col-1 text-right">
                    <button className="btn btn-sm btn-block btn-secondary">Views</button>
                    {/* <button className={`btn btn-sm btn-block btn-secondary ${displayMember}`} onClick={this.saveContent}>Save</button> */}
                  </div>
                </div>
              </div>
            </WorkboxBody>
          </Workbox>
        </WorkboxContainer>
      </Container>
    );
  }

}


const mapDispatchToProps = (dispatch) => {
  return {
    setShowMenu: (showMenu) => dispatch(setShowMenu(showMenu)),
    getProjectsWithoutShowLoading: () => dispatch(fetchProjects(false)),
    setShowUppy: (showUppy) => dispatch(setShowUppy(showUppy)),
    setShowButtons: (showButtons) => dispatch(setShowButtons(showButtons)),
    setShowMainTimelineProjects: (show) => dispatch(setShowProjects(show)),
    setShowProjectsList: (show) => dispatch(setShowProjectsList(show)),
    setProfileWorkboxHeight: (height) => dispatch(setProfileWorkboxHeight(height)),

    setMainSliderSize: (mainSliderSize) => dispatch(setMainSliderSize(mainSliderSize)),
    setShowMainSlider: (showMainSlider) => dispatch(setShowMainSlider(showMainSlider))
  }
}

const mapStateToProps = (state) => {
  const { showMenu, height, showUppy, showButtons, showProjectsList } = state.profileWorkbox;
  const { showMainSlider, mainSliderSize } = state.profile;
  const { rootId } = state.projects;
  return {
    rootId,
    wbHeight: height,
    showMenu,
    showUppy,
    showButtons,
    showMainSlider,
    mainSliderSize,
    showProjectsList
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWorkbox);
