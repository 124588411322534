import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Header from './components/header/Header';
import Profile from './pages/Profile';
import Login from './components/login/Login';
import Chatbox from './components/chat-box/Chatbox';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './app.css';

function App({ showChat }) {

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/">
          <Profile />
        </Route>
      </Switch>
      {showChat && <Chatbox />}
    </>
  );
}

const mapStateToProps = (state) => {
  const { showChat } = state.app;
  return {
    showChat
  };
};

export default connect(mapStateToProps)(App);
