import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { setShowChat } from './../../store/actions/appActions';
import { IoMdChatbubbles } from 'react-icons/io';
import { ReactComponent as Logo } from './../../assets/img/artisbox.svg';
import { ReactComponent as LeftGroup } from './../../assets/img/REC_Group_L.svg';
import { ReactComponent as RightGroup } from './../../assets/img/REC_Group_R.svg';
import './header.css';

const Header = ({ showChat, setShowChatBox }) => {

  const location = useLocation();
  const history = useHistory();

  const handleChatClick = (e) => {
    e.preventDefault();
    setShowChatBox(!showChat);
  }

  const handleLoginClick = (e) => {
    e.preventDefault();
    let goto = '/login'
    if (location.pathname === '/login') {
      goto = '/profile'
    }
    history.push(goto);
  }

  return (
    <header className="container-fluid aib-header">
      <div className="row h-100 align-items-center">
        <div className="col logo">
          <NavLink exact to="/profile" >
            <Logo />
          </NavLink>
        </div>
        <div className="col-6 d-flex justify-content-center explore">
          <LeftGroup />
          <span className="mx-4 text-center">explore</span>
          <RightGroup />
        </div>
        <div className="col d-flex justify-content-end header-menu">
          <ul className="nav">
            <li className="nav-item" >
              <a className="nav-link header-menu-link-icon" href="/" onClick={handleChatClick}><IoMdChatbubbles size={22} /></a>
            </li>
            <li className="nav-item">
              <NavLink exact to="/login" onClick={handleLoginClick} className="nav-link header-menu-link" >login</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );

};

const mapStateToProps = (state) => {
  const { showChat } = state.app;
  return {
    showChat
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowChatBox: (show) => dispatch(setShowChat(show))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
